<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:px-20 space-y-7">
      <div class="grid grid-cols-2 md:grid-cols-3 gap-4" v-if="playlists.data && playlists.data.length">
        <div class="card" v-for="(playlist, index) in playlists.data" :key="'playlist' + index">
          <router-link :to="{name: 'PlaylistDetail', params: {id: playlist.id, slug: playlist.slug}}">
            <img :src="playlist.thumbnail ? playlist.thumbnail.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
                 class="h-44 object-cover rounded-t-md shadow-sm w-full">
          </router-link>
          <div class="p-3">
            <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
              <router-link :to="{name: 'PlaylistDetail', params: {id: playlist.id, slug: playlist.slug}}">
                {{ playlist.title }}
              </router-link>
            </h4>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <v-pagination
            class="justify-content-center"
            v-if="playlists.paginatorInfo.lastPage > 1"
            v-model="playlists.paginatorInfo.currentPage"
            :pages="playlists.paginatorInfo.lastPage"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="loadPlaylists"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "PlaylistList",
  components: {
    VPagination
  },
  data() {
    return {
      playlists: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadPlaylists() {
      let query = `query($page: Int) {
        playlists(first: 12, page: $page, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "is_public", value: 1}]}){
          data {
            id
            title
            slug
            thumbnail {
              url
            }
            user {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.playlists.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.playlists) {
              this.playlists = data.data.playlists;
              console.log("playlists.paginatorInfo.lastPage", this.playlists.paginatorInfo.lastPage);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadPlaylists();
  }
}
</script>
